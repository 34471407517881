<template>
  <div id="saved_graph">
    <div class="d-flex justify-content-between">
      <!-- show graph data userwise -->
      <div class="mb-1">
        <i
          class="box-icon box-icon--effect bx bx-user-circle"
          :class="{ 'box-icon--active': showMyGraphs }"
          :title="showMyGraphs ? 'Show All Graphs' : 'Show My Graphs'"
          @click="toggleShowGraphs"
        ></i>
      </div>
      <div class="form-check form-switch">
        <label class="ed-label">{{
          libraryModelLocal ? "Quest Library" : "Graph Library"
        }}</label>
        <input
          v-if="isGameConnected"
          role="button"
          class="form-check-input"
          type="checkbox"
          @click="toggleLibraryFromQuestToGraph()"
          id="flexSwitchCheckChecked"
          :checked="graphLibraryView"
        />
      </div>
      <div class="d-flex align-items-center">
        <i
          class="fullcreentoggle custom-box-css d-flex box-icon box-icon--effect bx bx-search-alt-2"
          name="search-alt-2"
          title="Search Notes"
          @click="toggleSearch()"
        ></i>
        <i
          class="box-icon box-icon--effect"
          name="Change Library View"
          style="margin-left: 0.5rem"
          :class="
            graphLibarayView === 'grid' ? 'bx bx-list-ul' : 'bx bxs-grid-alt'
          "
          :title="
            graphLibraryView === 'grid' ? 'Switch to List' : 'Switch To Grid'
          "
          @click="toggleGraphLibraryView()"
        >
        </i>
        <i
          class="saved_graph_box__icon box-icon--effect box-icon bx bx-plus"
          @click="clearGraph"
        ></i>
      </div>
    </div>
    <div>
      <form
        v-if="searchToggle"
        class="d-flex mx-1 mt-2"
        v-on:submit.prevent="graphFilter()"
      >
        <input
          class="input-wrap form-input form-control"
          type="search"
          v-model="graphSearch"
          placeholder="Search Graph by graph Name and Username"
        />
      </form>
      <div
        class="d-flex"
        :class="
          graphPageNo > 0 ? 'justify-content-between' : 'justify-content-end'
        "
      >
        <div v-if="graphPageNo > 0" class="btn">
          <i
            class="custom-box-css d-flex box-icon box-icon--effect bx bx-chevrons-left"
            name="chevrons-left"
            title="Preivous Page"
            @click="previousGraph"
          ></i>
        </div>
        <div class="btn d-flex">
          <!-- <span class="badge bg-secondary"></span> -->
          <i
            v-if="graphLeft !== 0"
            class="custom-box-css d-flex box-icon box-icon--effect bx bx-chevrons-right"
            name="chevrons-right"
            :title="`${graphLeft} items left`"
            @click="nextGraph"
          ></i>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center" v-if="isFiltered">
      <a class="d-flex box-icon" @click="goBack()">
        <i class="bx bx-chevron-left box-icon"></i>
        <div>Back</div>
      </a>
    </div>
    <ul
      class="saved-graphs saved-graphs__layout"
      :class="
        graphLibraryView === 'grid'
          ? 'saved-graphs__layout--grid'
          : 'saved-graphs__layout--list list-group'
      "
    >
      <li class="save_graph_li save_graph_li--new">
        <button type="button" @click="clearGraph">
          <i class="bx bx-plus box-icon"></i>
          <label for="">Create New</label>
        </button>
      </li>
      <li
        class="save_graph_li"
        v-for="graph of allGraph"
        :key="graph._id"
        :class="{
          'list-group-item': true,
          graph__activate: isActive(graph) && !isUpdate,
          'list-group-item-warning': isActive(graph) && isUpdate,
          smart_graph_ai: isSmartGraph(graph),
        }"
        @click="getGraph(graph)"
        style="cursor: pointer"
      >
        <!-- <span>-- {{graph}} <br></span> -->
        <div v-if="!isFormActive(graph._id)" class="d-flex">
          <div style="width: 100%">
            <div class="d-flex justify-content-between align-items-center">
              <span class="saved_label">
                <strong> {{ graph.g6_data.graph_name }} </strong>
              </span>
              <span class="">
                <i
                  v-if="graph.access === 'custom'"
                  class="box-icon box-icon--disabled bx bx-customize"
                ></i>
                <i
                  v-if="graph.access === 'private'"
                  class="box-icon box-icon--disabled bx bx-lock-alt"
                ></i>
                <i
                  v-if="graph.access === 'public'"
                  class="box-icon box-icon--disabled bx bx-group"
                ></i>
              </span>
              <span>
                <i
                  v-if="graph.memory_id"
                  class="box-icon box-icon--disabled bx bx-bot"
                  title="Smart Graph"
                ></i>
              </span>
            </div>
            <span class="graph__category">
              {{ graph.g6_data.graph_type[0] }}
            </span>
          </div>
        </div>
        <div v-else class="d-flex">
          <div class="graph-lib__edit-form">
            <div class="graph-lib__input-form">
              <label class="ed-label">Graph Name</label>
              <ed-input
                :onChange="setGraphName"
                :value="graph_detail.graph_name"
                placeholder="Enter the Graph Name"
                size="large"
              />
            </div>
            <div class="graph-lib__input-form">
              <label class="ed-label">Select Category</label>
              <ed-dropdown
                :listItems="categories"
                :selected="graph_detail.graph_type"
                :isSelected="setGraphType"
              />
            </div>
            <div class="graph-lib__input-form">
              <label class="ed-label">Select Audience</label>
              <ed-dropdown
                :listItems="audience"
                :selected="graph_detail.graph_access"
                :isSelected="setGraphAccess"
              />
            </div>
            <div
              class="graph-lib my-3"
              v-if="graph_detail.graph_access === 'custom'"
            >
              <label class="ed-label">Add collabrators</label>

              <multiselect
                v-model="sharedAccess"
                @search-change="updateSharedOptions"
                :multiple="true"
                :options="usersList"
                label="username"
                track-by="username"
              ></multiselect>
            </div>
            <div class="graph-lib__input-form">
              <label class="ed-label">Tags</label>
              <ed-input
                :onChange="setGraphTags"
                placeholder="eg: animal, tech, naruto"
                :value="graph_detail.graph_tags"
                size="large"
              />
            </div>
          </div>
        </div>
        <span
          v-if="graph.tags.length > 0 && !isFormActive(graph._id)"
          class="graph_tags"
        >
          <span
            class="px-1"
            :style="{ color: getRandomColor() }"
            @click="fetchByTag(tag)"
            v-for="tag in graph.tags"
            :key="tag.id"
            >#{{ tag.name }}</span
          >
        </span>
        <div
          :class="
            isFormActive(graph._id)
              ? 'saved_graph_box saved_graph_box__edit'
              : 'saved_graph_box saved_graph_box__show'
          "
        >
          <i
            v-if="isActive(graph) && graph.memory_id"
            title="Create New Thread"
            class="saved_graph_box__icon box-icon bx bxs-store-alt"
            @click="setActiveStoreGraph(graph)"
          ></i>
          <i
            v-if="isActive(graph) && !graph.memory_id"
            title="Create Smart Graph"
            class="saved_graph_box__icon box-icon bx bx-bot"
            @click="convertItIntoSmartGraph(graph)"
          ></i>
          <i
            v-if="isActive(graph) && graph.memory_id"
            title="Reset Current Thread"
            class="saved_graph_box__icon box-icon bx bx-run"
            @click="changeNewThread(graph)"
          ></i>
          <!-- <i
            v-if="isActive(graph) && graph.memory_id"
            title="Clone my graph"
            class="saved_graph_box__icon box-icon bx bx-copy-alt"
            @click="cloneSmartGraph(graph)"
          ></i> -->
          <i
            v-if="
              isAccess(graph.actor === null ? 'someone' : graph.actor.username)
            "
            title="Delete graph"
            class="saved_graph_box__icon box-icon bx bx-trash"
            @click="deleteGraph(graph)"
          ></i>
          <i
            title="Edit Graph"
            v-if="
              isActive(graph) &&
              isAccess(graph.actor === null ? 'someone' : graph.actor.username)
            "
            @click="toggleForm(graph)"
            class="saved_graph_box__icon box-icon box-icon--active bx bx-edit-alt"
          ></i>
          <i
            title="Update graph"
            v-if="
              isActive(graph) &&
              isAccess(graph.actor === null ? 'someone' : graph.actor.username)
            "
            @click="saveForm(graph)"
            class="saved_graph_box__icon box-icon bx bx-save"
          ></i>
          <i
            title="Share graph"
            class="saved_graph_box__icon box-icon bx bx-share"
            @click="shareUrl(graph, $event)"
            @mouseout="tooltipOut"
          >
          </i>
          <i
            title="Graph Embed"
            class="saved_graph_box__icon box-icon bx bx-expand-alt"
            @click="previewGraph(graph)"
          ></i>
          <i
            title="Add to Graph"
            class="saved_graph_box__icon box-icon bx bx-cloud-snow"
            @click="addToThoughtMap(graph)"
          ></i>
          <i
            v-if="graph.memory_id && isGameConnected"
            title="Open in School of Unlearn"
            class="saved_graph_box__icon box-icon bx bx-game"
            @click="
              addToGame(graph.memory_id, graph._id, graph.g6_data.graph_name)
            "
          ></i>
          <i title="Clone Graph" class='saved_graph_box__icon box-icon bx bx-hourglass' @click="cloneGraph(graph._id)"></i>
          <i
            v-if="isActive(graph) && nodaConnected"
            title="Add Graph To Noda"
            class="saved_graph_box__icon box-icon bx bx-bowling-ball"
            @click="addGraphToNoda(graph)"
          ></i>
        </div>
        <div class="author-container">
          <span class="author-name" v-if="!isFormActive(graph._id)">
            @{{ graph.actor === null ? "someone" : graph.actor.username }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2';
import chroma from 'chroma-js';
import graphReactor from '@/common/core/Graph/graphReactor';
import edInput from '@/common/components/ed-forms/ed-input.vue';
import edDropdown from '@/common/components/ed-forms/ed-dropdown.vue';
import mapExploreGraphToNodaVr from '@/utils/mapExploreGraphToNodaVr';
/* eslint-disable no-underscore-dangle */

export default {
  async mounted() {
    window.noda.onInitialized = () => {
      if (!window.noda.isInstalled()) {
        this.nodaConnected = true;
      }
    };
    await this.fetchAllGraph();
  },
  components: {
    edInput,
    edDropdown,
  },

  data() {
    return {
      searchToggle: false,
      activeGraphId: {
        quest_library: '',
        explore_library: '',
      },
      graphSearch: '',
      nodaConnected: false,
      graphPageNo: 0,
      graphData: [],
      showMyGraphs: true,
      audience: ['public', 'private', 'custom'],
      sharedAccess: null,
      categories: [
        'Science',
        'Technology & Gadgets',
        'Entertainment & Media',
        'Business',
        'Games & Sports',
        'Art & Culture',
        'Educational',
        'Travel - Holidays, Places',
        'Food & Drinks',
        'History & Events',
        'Finances',
        'Health & Wellbeing',
        'Uncategorized',
      ],
      listToggle: {
        id: null,
        show: false,
      },
      graph_detail: {
        graph_name: '',
        graph_type: '',
        graph_tags: '',
        graph_access: '',
      },
      isUpdate: false,
      isFiltered: false,
    };
  },

  computed: {
    storeActiveGraphId() {
      return this.$store.state.active_graph_id;
    },
    libraryMode() {
      return this.$store.state.library_mode_quest;
    },
    usersList() {
      return this.$store.state.gmodule.usersList;
    },
    getUserFilter() {
      return this.showMyGraphs ? '@me' : '@all';
    },
    userNameState() {
      return this.$store.state.auth.user.username;
    },
    allGraph() {
      // eslint-disable-next-line
      return this.$store.state.saved_graph_list.data;
    },
    graphLeft() {
      return this.$store.state.saved_graph_list.left;
    },
    graphLibraryView() {
      return this.$store.state.graph_library_view;
    },
    libraryModelLocal() {
      return this.$store.state.library_mode_quest;
    },
    isGameConnected() {
      // console.log('xvf', 'is Game connected', this.$store.state.game.connected);
      return this.$store.state.game.connected;
    },
    // getRandomColor() {
    //   return chroma.random().darken().hex();
    // },
  },

  created() {
    // this.unsubscribe = this.$store.subscribe((mutation) => {
    //   if (mutation.type === 'SET_SAVED_GRAPH') {
    //     this.fetchAllGraph();
    //   }
    // });
  },
  sockets: {
    userCreatedGraph(data) {
      if (this.showMyGraphs === false) {
        this.$store.dispatch('addNewSavedGraph', data);
      }
    },
    updaterUserGraph(data) {
      if (this.showMyGraphs === false) {
        this.$store.dispatch('updateGraphLibrary', data);
      }
    },
    deleteUserGraph(id) {
      if (this.showMyGraphs === false) {
        this.$store.dispatch('deleteItemFromGraphLibrary', id);
      }
    },
    refreshGraphList() {
      if (this.showMyGraphs === false) {
        this.fetchAllGraph();
      }
    },
    //   newNotification(message) {
    //     const msg = JSON.parse(message);
    //     if (msg.verb.display.en === 'GRAPHED') {
    //       this.fetchAllGraph();
    //     }

    //     if (msg.verb.display.en === 'DELETED_GRAPH') {
    //       this.fetchAllGraph();
    //     }

    //     if (msg.verb.display.en === 'UPDATED_GRAPH') {
    //       if (this.$store.state.auth.user.username === msg.actor.username) {
    //         this.isUpdate = false;
    //         this.fetchAllGraph();
    //       }
    //     }
    //   },
    // },
  },

  watch: {
    storeActiveGraphId(val) {
      this.activeGraphId = val;
    },
    libraryMode() {
      this.fetchAllGraph();
    },
    async graphSearch(newVal) {
      // console.log('searching', newVal);
      if (newVal === '') {
        this.graphPageNo = 0;
        await this.fetchAllGraph();
      }
    },
    allGraph(val) {
      // console.log('allGraph', val);
      this.graphData = val;
    },
    showMyGraphs(flag) {
      if (flag) {
        this.graphPageNo = 0;
        this.fetchGraphData({
          user: this.getUserFilter,
          type: 'page',
          pageNo: this.graphPageNo,
        });
      } else {
        this.graphPageNo = 0;
        this.fetchGraphData({
          user: this.getUserFilter,
          type: 'page',
          pageNo: this.graphPageNo,
        });
      }
    },
  },

  methods: {
    setActiveStoreGraph(graph) {
      this.$store.dispatch('gmodule/setActiveVectorStore', graph);
    },
    convertItIntoSmartGraph(graph) {
      console.log(graph);
      this.$store.dispatch('gmodule/convertItIntoSmartGraph', graph);
    },
    changeNewThread(graph) {
      this.$store.dispatch('gmodule/resetThread', graph);
    },
    updateSharedOptions(val) {
      this.$store.dispatch('gmodule/userListSearchUpdate', val);
    },
    async clearGraph() {
      if (this.isGameConnected) {
        const { value: graphName } = await Swal.fire({
          title: 'Create Graph',
          input: 'text',
          inputLabel: 'Enter the name of the New Graph',
          inputPlaceholder: 'Graph name',
          showCancelButton: true,
          confirmButtonText: 'Create',
        });

        if (graphName) {
          console.log('Graph name entered:', graphName);
          try {
            const payloadObj = {
              graph_name: graphName,
              gdata: {
                nodes: [],
                edges: [],
                combos: [],
              },
              graph_type: ['Uncategorized'],
              graph_tags: 'custom',
              graph_access: 'custom',
              sharedAccess: [],
              graph_dimension: 'explore_library',
              ...(this.graphAccess === 'custom' && {
                sharedAccess: this.sharedAccess.map((i) => i.id),
              }),
            };

            const eventPayload = {
              verb: 'GRAPH_EXPLORE_SAVE',
              value: {
                title: payloadObj.graph_name,
              },
            };
            this.$store.dispatch('eventToEventStore', eventPayload);

            const graph = await this.$store.dispatch('saveCurrentGraph', payloadObj);
            console.log('xvf', 'new updated graph', graph);
            this.convertItIntoSmartGraph(graph);
          } catch (error) {
            console.error('Error while saving graph: ', error);
          }
        } else if (Swal.DismissReason.cancel) {
          console.log('Cancelled');
        }
      } else {
        const value = await Swal.fire({
          title: 'New Graph',
          text: 'Are you sure you want to destroy this graph?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Save',
          denyButtonText: "Don't save",
        });

        if (value.isConfirmed) {
          console.log(value, 'Denied');
          try {
            const timestamp = Date.now();
            const humanReadableTimestamp = new Date(timestamp).toLocaleString();
            const username = this.user?.username;
            const randomGraphName = `${username}_${humanReadableTimestamp}`;

            const payloadObj = {
              graph_name: this.$store.state.gmodule.anchor
                ? this.$store.state.gmodule.anchor
                : randomGraphName,
              gdata: graphReactor.exploreInstance.save(),
              graph_type: ['Uncategorized'],
              graph_tags: 'custom',
              graph_access: 'custom',
              sharedAccess: [],
              graph_dimension: 'explore_library',
              ...(this.graphAccess === 'custom' && {
                sharedAccess: this.sharedAccess.map((i) => i.id),
              }),
            };

            const eventPayload = {
              verb: 'GRAPH_EXPLORE_SAVE',
              value: {
                title: payloadObj.graph_name,
              },
            };
            this.$store.dispatch('eventToEventStore', eventPayload);

            await this.$store.dispatch('saveCurrentGraph', payloadObj);
          } catch (error) {
            console.error('Error while saving graph: ', error);
          }
        } else if (value.isDenied) {
          console.log(value, 'Confirmed');

          this.$store.dispatch('gmodule/clearGraphID');
          graphReactor.exploreInstance.changeData({ nodes: [], edges: [] });
          this.$store.dispatch('gmodule/setExploreData', {
            nodes: [],
            edges: [],
            combos: [],
          });
        } else {
          console.log('Cancelled');
        }
      }
    },
    async fetchGraphData(payload) {
      const res = await this.$store.dispatch('fetchAllGraph', payload);
      this.graphData = res;
    },

    tooltipOut(elem) {
      // eslint-disable-next-line
      elem.target.setAttribute("title", "Copy embed code");
    },

    selectCategory(tab) {
      this.selectedCategories = tab;
    },

    goBack() {
      this.fetchAllGraph();
    },

    isAccess(username) {
      return (
        this.userNameState === username
        || this.$store.state.admin.includes(this.userNameState)
      );
    },

    addToGame(memoryId, graphId, graphName) {
      // console.log('xvf', 'Various mobility', memoryId, graphId, graphName);
      let threadId;
      let vectorStoreId;
      if (typeof memoryId === 'string') {
        threadId = memoryId;
        vectorStoreId = null;
      } else {
        threadId = memoryId.thread_id;
        vectorStoreId = memoryId.vector_store_id;
      }
      const response = {
        data: {
          type: 'threadId',
          status: 200,
          threadId,
          vectorStoreId,
          graphId,
          graphName,
        },
      };
      window.parent.postMessage(
        JSON.stringify(response),
        process.env.VUE_APP_GAME_URI,
      );
    },

    isFormActive(id) {
      return this.listToggle.id === id && this.listToggle.show;
    },

    toggleSearch() {
      this.searchToggle = !this.searchToggle;
    },

    async graphFilter() {
      this.graphPageNo = 0;
      const payload = {
        user: this.getUserFilter,
        type: 'search',
        searchWord: this.graphSearch,
        pageNo: this.graphPageNo,
      };
      await this.$store.dispatch('fetchAllGraph', payload);
    },

    async nextGraph() {
      if (this.graphSearch === '') {
        const payload = {
          user: this.getUserFilter,
          type: 'page',
          pageNo: this.graphPageNo + 1,
        };
        this.$store.dispatch('fetchAllGraph', payload);
      } else {
        const payload = {
          user: this.getUserFilter,
          type: 'search',
          searchWord: this.graphSearch,
          pageNo: this.graphPageNo + 1,
        };
        await this.$store.dispatch('fetchAllGraph', payload);
      }
      this.graphPageNo += 1;
    },

    async previousGraph() {
      if (this.graphSearch === '') {
        const payload = {
          user: this.getUserFilter,
          type: 'page',
          pageNo: this.graphPageNo - 1,
        };
        this.$store.dispatch('fetchAllGraph', payload);
      } else {
        const payload = {
          user: this.getUserFilter,
          type: 'search',
          searchWord: this.graphSearch,
          pageNo: this.graphPageNo - 1,
        };
        await this.$store.dispatch('fetchAllGraph', payload);
      }
      this.graphPageNo -= 1;
    },

    createPayload(graph) {
      const { id } = graph;
      return {
        id,
        graph_name: this.graph_detail.graph_name,
        graph_type: [this.graph_detail.graph_type],
        graph_tags: this.graph_detail.graph_tags,
        graph_access: this.graph_detail.graph_access,
        sharedAccess: this.sharedAccess.map((i) => i.id),
        gdata:
          graph.graph_dimension === 'quest_library'
            ? this.$store.state.quests.quest_graph_data
            : graphReactor.exploreInstance.save(),
      };
    },

    async updateGraph(payload) {
      this.isUpdate = true;
      const updatedData = await this.$store.dispatch(
        'gmodule/updateGraph',
        payload,
      );
      this.$socket.emit('update_graph', updatedData);
      this.isUpdate = false;
      return updatedData;
    },

    async toggleForm(graph) {
      const { id } = graph;
      this.listToggle = {
        id,
        show: !this.listToggle.show,
      };

      if (this.listToggle.show === false) {
        const payload = this.createPayload(graph);
        await this.updateGraph(payload);
      }
    },

    async saveForm(graph) {
      const payload = this.createPayload(graph);
      await this.updateGraph(payload);
      // close after graph is saved
      this.listToggle.show = !this.listToggle.show;
    },

    getRandomColor() {
      return chroma.random().darken().hex();
    },

    previewGraph(payload) {
      console.log('graph data ', payload);
      const pLink = document.createElement('a');
      if (payload.graph_dimension === 'explore_library') {
        pLink.setAttribute('href', `/embed/GraphFrame/${payload.id}`);
      } else {
        pLink.setAttribute('href', `/embed/QuestFrame/${payload.id}`);
      }
      pLink.setAttribute('target', '_blank');
      pLink.click();
      pLink.remove();
      this.$store.commit('SET_ACTIVE_GRAPH_ID', payload.id);
    },
    shareUrl(graph, $event) {
      let iframeUrl;
      if (graph.graph_dimension === 'explore_library') {
        iframeUrl = `${window.location.origin}/embed/GraphFrame/${graph.id}`;
      } else {
        iframeUrl = `${window.location.origin}/embed/QuestFrame/${graph.id}`;
      }
      const codePaste = `<iframe width="100%" height="840" src="${iframeUrl}"></iframe>`;
      navigator.clipboard.writeText(codePaste);
      // eslint-disable-next-line
      $event.target.setAttribute("title", "Copied");
    },

    isActive(graph) {
      return (
        this.$store.state.active_graph_id[graph.graph_dimension] === graph._id
      );
    },
    isSmartGraph(graph) {
      return Boolean(graph.memory_id);
    },
    async cloneGraph(graphId) {
      await this.$store.dispatch('gmodule/cloneGraph', { graphId });
    },
    async fetchAllGraph() {
      // console.log('fetchAllGraph', this.graphSearch);
      await this.$store.dispatch('fetchAllGraph', {
        user: this.getUserFilter,
        type: 'page',
        pageNo: 0,
      });
      this.isFiltered = false;
    },
    async fetchByTag(val) {
      const { name } = val;

      // eslint-disable-next-line
      await this.$store.dispatch("fetchGraphByTag", name);
      this.isFiltered = true;
    },
    deleteGraph(graph) {
      const isDeleted = this.$store.dispatch('gmodule/deleteGraph', graph);
      if (isDeleted) {
        this.$socket.emit('delete_graph', graph.id);
      }
    },
    getGraph(graph) {
      if (this.isGameConnected) {
        this.addToGame(graph.memory_id, graph._id, graph.g6_data.graph_name);
      } else if (graph._id !== this.activeGraphId[graph.graph_dimension]) {
        if (graph.metadata) {
          if (graph.metadata.anchor) {
            this.$store.dispatch(
              'gmodule/updateTextAsAnchor',
              graph.metadata.anchor,
            );
          }
        }
        this.listToggle = {
          id: null,
          show: false,
        };
        console.log(graph._id);
        const graphObj = {
          graphId: graph._id,
          memory_id: graph.memory_id,
          label: graph.g6_data.graph_name,
          type: 'graph',
          ...(graph.metadata && { metadata: graph.metadata }),
          questLibrary: this.libraryModelLocal,
          ...graph,
        };
        this.$store.dispatch('setCurrentThought', { thoughtObject: graphObj });
        // trigger event for opening graph
        const eventPayload = {
          verb: 'OPENED_SAVED_GRAPH',
          value: {
            title: graph.g6_data.graph_name,
            enload: graph,
          },
        };
        this.$store.dispatch('eventToEventStore', eventPayload);
        this.$store.dispatch('actionFeedback', eventPayload);
        this.graph_detail = {
          graph_name: graph.g6_data.graph_name,
          graph_type: graph.g6_data.graph_type[0],
          graph_tags: graph.tags.map((tag) => tag?.name).join(', '),
          graph_access: graph.access,
        };
        this.sharedAccess = graph.sharedAccess.map((i) => ({
          id: i.id,
          username: i.username,
        }));
        this.activeGraphId[graph.graph_dimension] = graph._id;
      }

      // this.$store.dispatch('gmodule/expandGraphDataUsingVector');
      // alt+shift+g
    },

    // toggle graph Library View
    toggleGraphLibraryView() {
      if (this.graphLibraryView === 'grid') {
        this.$store.dispatch('toggleGraphLibraryView', 'list');
      } else {
        this.$store.dispatch('toggleGraphLibraryView', 'grid');
      }
    },

    // AddToThoughMat
    addToThoughtMap(graph) {
      const payload = {
        graphId: graph._id,
        graphName: graph.g6_data.graph_name,
      };

      this.$store.dispatch('gmodule/addToThoughtMap', payload);
    },
    async addGraphToNoda(graph) {
      await window.noda.clearMap();
      console.log('xvf', graph);
      console.log('xvf', this.$store.state.gmodule.g6_explore_data);
      const nodaGraph = mapExploreGraphToNodaVr(
        this.$store.state.gmodule.g6_explore_data,
        this.$store.state.gmodule.vrScale,
      );
      this.$store.dispatch('gmodule/setVrGraph', { graph: nodaGraph });
      console.log('xvf', nodaGraph);
      await window.noda.loadJson({ jsonString: nodaGraph });
    },

    setGraphName(val) {
      this.graph_detail.graph_name = val;
    },
    setGraphTags(val) {
      this.graph_detail.graph_tags = val;
    },
    setGraphType(val) {
      this.graph_detail.graph_type = val;
    },
    setGraphAccess(val) {
      this.graph_detail.graph_access = val;
    },
    toggleShowGraphs() {
      this.showMyGraphs = !this.showMyGraphs;
    },
    toggleLibraryFromQuestToGraph() {
      this.$store.dispatch('toggleGraphLibrary', !this.libraryModelLocal);
    },
  },
  beforeDestroy() {
    // this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/variables";
.saved_label {
  cursor: pointer;
  // text-decoration: underline;
  color: $edvantaBlue;
  text-transform: capitalize;
}

.tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltiptext {
  visibility: visible;
  opacity: 1;
}

.graph {
  &__activate {
    background: rgba($secondaryBlue, 0.1) !important;
  }
  &__category {
    display: block;
    // white-space: nowrap;
    color: $gray;
    font-size: 0.9rem;
  }

  .smart_graph {
    background: rgba($secondaryBlue, 0.1) !important;
  }
}

.graph-lib {
  &__edit-form {
    margin-bottom: 1.3rem;
    &__text {
      width: 100%;
      font-family: inherit;
      margin-bottom: 0.5rem;
      padding: 0.1rem;
      padding-left: 0.4rem;
      border-radius: 0.3rem;
    }
    &__dropdown {
      width: 100%;
      font-family: inherit;
      margin-bottom: 0.5rem;
    }
  }
  &__input-form {
    margin-bottom: 0.5rem;
  }
}

.select-cat {
  width: auto;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  padding: 0.5em;
  border-radius: 5px;
  transition: all ease-in-out 200ms;

  &:hover {
    background-color: $edvantaButtonColor;
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.graph_tags {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  &__container {
    transition: all 0.3s ease-in-out;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.author-name {
  font-size: 0.85rem;
  font-style: italic;
}

.saved-graphs {
  &__layout {
    display: flex;
    flex-wrap: nowrap;
    padding: 0rem;

    &--grid {
      flex-direction: row;
      flex-wrap: wrap;

      .save_graph_li {
        width: 24rem;
        // padding: .4rem;
        // border: 1px solid rgba(0, 0, 0, 0.125);
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.125);
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }

    &--list {
      // flex-direction: column;
      display: block;
    }
  }

  .smart_graph_ai {
    background-color: rgba(182, 235, 235, 0.822);
  }
  .save_graph_li {
    min-height: 200px;
    padding: 10px;
    position: relative;
    border-left: none;
    border-right: none;
    border-radius: 0;

    &--new {
      flex-direction: column;
      border-style: dashed;
      border-color: rgb(202, 202, 202);
      position: relative;

      button {
        border: none;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        label {
        }
        i {
          font-size: 5rem;
        }
      }
    }

    &:hover > .saved_graph_box,
    &:hover > .tag-container {
      display: flex;
    }
  }
  .categories {
    position: absolute;
    bottom: 0;
    left: 5px;
    padding-top: 15px;
    padding-right: 5px;
  }
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0.8rem 0;
  }
  .author-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0.8rem;
    bottom: 0.5rem;
  }
  .saved_graph_box {
    // width: 70px;
    // height: 100%;
    position: absolute;
    z-index: 2;
    padding-top: 1rem;
    padding-left: 0.8rem;
    justify-content: center;
    align-items: center;

    &__edit {
      left: 0.5rem;
      bottom: 0.3rem;
    }

    &__show {
      display: none;
      right: 0.5rem;
      bottom: 2rem;
    }

    &__icon {
      transition: all 0.3s ease-in-out;
      fill: $color-grey-1;
      margin-left: 0.3rem;
      &:hover {
        // padding: 5px;
        transform: translateY(-3px);
        background-color: rgba(243, 243, 243, 0.664);
        fill: $gray;
        border-radius: 50%;
        cursor: pointer;
      }
      &--highlight {
        fill: $edvantaBlue;

        &:hover {
          fill: $edvantaBlue;
        }
      }
    }
  }
}
.add_icon {
  transform: scale(1.5);
}
</style>
